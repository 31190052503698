.collapse-header{
    padding: 10px;
    border-radius: 2px;
    border: 1px solid #ccc;
    background-color: #ccc;
    cursor: pointer;
}

.collapse-body {
    border: 5px solid #ccc;
    padding: 10px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.03) !important;
}
