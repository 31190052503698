
:global(.heading) {
  color: #f7993d !important;
  padding: 0.5em;
  padding-top: 0.25em;
  width: 100%;
  border-radius: 0.3125em;
  color: white;
  font-size: 1.875rem;
  font-family: 'Barlow', sans-serif;
}

:global(.centerForm) {
  background-color: white;
  border-radius: 0.375rem;
  margin: 1.25rem auto 0;
  text-align: center;
  width: 100%;
}

:global(.labelText) {
  color: black !important;
  /*
  font-weight: bold;
  */
}

:global(.inputItem){

}
:global(.inputItem) {
  margin-left: auto;
  margin-right: auto;
  background-color: white !important;
  border-radius: .3125rem !important;
  border: 0.125rem solid #F58220 !important;
  transition: none !important;
}

a {
  font-size: 1rem;
  text-decoration: none !important;
  color: #F58220 !important;

}

:global(.alignLeft) {
  text-align: left;
}