.sidebar_nav li > a {
  color: #fff !important;
  opacity: 0.8;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 14%;
  padding-right: 24px;
  display: block;
  background-size: 0;
}

.sidebar_nav li > a:hover {
  background: none;
  color: #fff;
  background-repeat: no-repeat;
  background-image: linear-gradient(88deg, #F58220 4%, rgba(102, 157, 210, 0.18) 87%);
  background-size: 100%;
  text-decoration: none;
  transition: all .5s ease;
}

.sidebar_nav li > a.active {
  background-size: 100%;
  background-image: linear-gradient(88deg, #F58220 4%, rgba(102, 157, 210, 0.18) 87%);
  color: #fff !important;
  opacity: 1 !important;
}

.sidebar_nav li > a::after {
  content: '';
  border-right: 0;
  border-top: 26px solid transparent;
  border-bottom: 26px solid transparent;
  position: absolute;
  right: -1px;
  margin-top: -12px;
  transition: all .3s ease-in-out;
}

.sidebar_nav li > a.active::after {
  border-right: 30px solid #f2f2f2;
}

.sidebar_nav li > a > img {
  width: 20px;
  margin-right: 14px;
  position: relative;
  top: -3px;
}

.sidebar_nav {
  margin-top: 20px;
  padding-left: 0;
  list-style-type: none;
}

.sidebar_header {
  background: linear-gradient(to top, transparent 100%, #F58220 100%, #F58220 60%) !important;
  text-align: center;
}

.sidebar_header img {
  margin-top: 15px;
  width: 160px;
  object-fit: contain;
  height: 80px;
}

.sidebar_title {
  padding-left: 10px !important;
  font-weight: 500;
}


.sidebar-link:hover {
  background: none !important;
}
.sidebar-link > a {
  color: white !important;
}

