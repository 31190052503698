:global(.capitalizeText) {
  text-transform: capitalize;
}

.cancel-portal-creation-btn:hover .cancel-portal-anchor {
  color: #fff !important;
}

.send-button{
  width: 50px;
  margin-left:4px;
  color: white;
}
.popover{
  width: 500px;
}

.popover-body{
  background: #1b1e21;
}

#chat_box_popover{
  overflow-y: auto;
  width: inherit;
  height: 500px;
}

#chat_box_popover_empty{
  overflow-y: auto;
  width: inherit;
}

.message-current-user{
  border-radius: 25px;
  background: green;
  padding: 6px 6px 6px 10px;
  text-align: left;
  color: white;
}

.typed-message{
  border-radius: 25px;
}

.message-other-users{
  border-radius: 25px;
  background: gray;
  padding: 6px 6px 6px 10px;
  text-align: left;
  color: white;
}

.div-current-user{
  margin-left: 20px;
}

.div-other-users{
  margin-right: 20px;
}

.badge {
  position: absolute;
  top: -10px !important;
  right: -10px;
  background-color: orange;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.card{
  width: 100% !important;
  margin-top: 10px!important;
}

.card-header{
  border-bottom: None !important;
  font-weight: bold  !important;
}

.project_list_popover .arrow::after {
  border-right-color: black !important;
}


.projectList{
  margin: 0px;
}


.project_list_popover .popover-body {
  /* Adjust padding or dimensions to change background size */
  padding: 2px !important;
}

.createPortalForm{
  background: transparent;
  width: 100% !important;
}


.small-dropdown {
  width: 100px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.Model {
  text-align: center;
  padding: 20px;
}

input[type="file"] {
  margin: 20px 0;
}

img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}