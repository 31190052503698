
.btn-warning-lg {
  color: #fff;
  background-color: #f8981d;
  width: 20%;
  height: 60px;
  font-size: 16px;
  border: none;
  border-radius: 7px;
}

.btn-info {
  background-color: #1da0f8 !important;
  border-color: #157fc5;
}

.btn {
  padding: 2px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.table td {
  padding: 0.5rem !important;
  vertical-align: middle !important;
  text-align: center;
}

.table th {
  text-align: center;
}

.cards-container {
  display: flex;
}

.custom-trends > img {
  margin-left: 15% !important;
  width: 65% !important;
}

.card {
  background-color: transparent !important;
  border: none !important;
  width: 250px !important;
  margin: auto !important;
  margin-top: 40px !important;
}

.card-img-top {
  width: 50% !important;
  margin: 25% !important;
}

.card-body {
  text-align: center !important;
  padding: 1.25rem 0 0 0 !important;
  color: #5a5a5a !important;
}

.custom-img {
  background-color: #fff;
  height: 250px;
  border: solid 1px #707070;
}

.button-section::before,
.button-section::after {
  content: "";
  width: 23.4%;
  height: 2px;
  vertical-align: super;
  background-color: #cccc;
  display: inline-block;
}

.card::before {
  content: '';
  width: 2px;
  height: 73px;
  background: #ccc;
  position: absolute;
  z-index: -1;
  bottom: 100%;
  left: 49.5%;
}

.card1::after,
.card2::after {
  content: "";
  width: 70%;
  height: 2px;
  background-color: #cccc;
  position: absolute;
  left: 100%;
  top: 30%;
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .custom-img {
    height: 200px !important;
  }

  .card {
    width: 200px !important;
  }

}
