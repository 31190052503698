.register-button-wrapper {
  max-width: 400px;
}

.register-button-wrapper span {
  white-space: nowrap;
  font-size: 1.1rem;
}
.custom-width {
  max-width: 400px;
}

.form-control:disabled {
  background-color: #ccc !important;
  border-color: #aaa !important;
}

.disabled {
  color: rgb(170,170,170);
}