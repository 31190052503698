/* login form styles */
.login-form {
  -webkit-box-shadow: 0 2px 1px #f1f1f1;
  -moz-box-shadow: 0 2px 1px #f1f1f1;
  box-shadow: 0 2px 1px #f1f1f1;
  background-color: white;
  border-radius: .3em;
  border: 1px solid #e9e9e9;
  border-bottom-color: #ddd;
  margin-bottom: 2em;
  padding: 28px 5px 30px 5px;
  max-width: 800px;
  margin: auto;
}

@media (min-width: 768px) {
  .brand-logo {
    border-right: 1px solid #eee;
    min-height: 185px;
    line-height: 185px;
    padding-right: 30px;
    float: left !important;
    width: 50% !important;
  }
}

@media (max-width: 768px){

}

.brand-logo {
  max-width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}

.brand-logo a {
  width: 100%;
}
.brand-logo img {
  vertical-align: middle;
  /*max-width: 100%;*/
  object-fit: contain;
  max-width: 200px;
  max-height: 150px;
  min-width: 100px;
  min-height: 70px;
  /*width: 100%;*/
  /*height: auto;*/
}
.login-wrapper {
  margin: 80px 0 0 0 !important;
}
.login-wrapper p, label {
  font-size: 1rem;
}
.sign-in-button {
  background: linear-gradient(to bottom, #1f8be6 0%, #1178ce 100%) #1385e5;
  border-width: 1px;
  border-style: solid;
  border-color: #1178ce #0f6ab7 #0e64ac;
  border-radius: 0.3rem;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  height: 2.4em;
  line-height: 2.3em;
  margin-bottom: 0;
  padding: 0 1em;
  outline: 0;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-user-select: none;
}

.login-form-label {
  color: #7F7F7F;
  font-weight: normal;
  display: inline-block;
  margin-bottom: 0.5em;
}

.powered_by {
  align-items: center;
  justify-content: center;
  color: #7F7F7F;
  margin-top: 0.5em;
}
