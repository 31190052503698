.top-navbar {
  background: linear-gradient(to right, transparent 0%, rgb(255, 160, 122) 15%, #F58220 60%) !important;
  height: 3rem !important;
  font-family: 'Alata', sans-serif;
}

.activeLink {
  color: white !important;
  border-bottom: 0.1875rem solid white !important;
}

.img-circle {
  width: 1.875rem;
  border-radius: 50%;
  margin-top: -5px;
}

.noti-badge:after {
  content: "";
  position: absolute;
  background: #f8981d;
  height: .5rem;
  right: .8rem;
  width: .5rem;
  border-radius: 50%;
  border: 1px solid rgb(238, 235, 235);
}


/* .dropdown-menu {
    border: none !important;
    border-radius: 0 !important;
} */

.dropdown-toggle::after {
  border-top: 0 !important;
  border-left: 0.12em solid !important;
  border-bottom: 0.12em solid !important;
  border-right: 0 !important;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  transform: rotate(-45deg);
  width: 0.45em;
  transition: transform .3s ease-in-out;
}

.dropdown.show a::after {
  transform: rotate(-225deg)
}

.top-navbar a {
  color: white !important;
  font-size: 1rem !important;
}
